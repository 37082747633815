import { Lobby } from "./components/lobby/lobby";
import { EnterCode } from "./components/enter-code/enter-code";
import { Admin } from "./components/admin/admin";
import { Settings } from "./components/settings/settings";

const AppRoutes = [
    {
        index: true,
        element: <EnterCode />
    },
    {
        path: ':junk',
        index: true,
        element: <EnterCode />
    },
    {
        path: 'lobby/',
        index: true,
        element: <Lobby />
    },
    {
        path: 'lobby/:code',
        index: true,
        element: <Lobby />
    },
    {
        path: 'settings',
        index: true,
        element: <Settings />
    },
    {
        path: 'admin',
        index: true,
        element: <Admin />
    },
    {
        path: 'admin/:code',
        index: true,
        element: <Admin />
    }
];

export default AppRoutes;
