import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import './enter-code.css'

export function EnterCode() {
    const [code, setCode] = useState('');
    const [valid, setValid] = useState(false);
    const navigate = useNavigate();
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    const codeChanged = async (event) => {
        const thecode = event.target.value.toUpperCase().trim();
        setCode(thecode);
        if (thecode.length === 0) {
            setValid(false);
        } else {
            const isValid = await checkCode(thecode);
            setValid(isValid);
        }
    }

    const checkCode = async (code) => {
        if (!code || code.length === 0) {
            return false;
        }
        const response = await fetch('api/admin/checkcode/' + code, {
            headers
        });
        const data = await response.json();
        return data.exists;
    }

    const gotoApp = () => {
        localStorage.setItem('spelly-code', code);
        navigate('/lobby/' + code);
    }

    useEffect(() => {
        const code = localStorage.getItem('spelly-code');
        if (code) {
            navigate('/lobby/' + code);
        }        
    }, []);

    return (
        <div className="code">
            <div className="box">
                <div className="input">
                    <label htmlFor="codeInput" className="form-label">Enter Code</label>
                    <input type="text" className={`form-control ${ valid ? 'is-valid' : '' }`} id="codeInput" value={code} onChange={codeChanged} />
                </div>
                
                <div className="continue-btn">
                    <Button disabled={!valid} onClick={gotoApp} color={valid ? 'primary' : 'secondary'}>Continue</Button>
                </div>
            </div>
        </div>
    );
}