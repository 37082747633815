import React, { useEffect, useState } from 'react';
import './settings.css';

export function Settings() {
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState(0);
    const [ttsText, setTtsText] = useState('This is a test sentence');
    const speechApi = window.speechSynthesis;

    const testSpeech = (voice) => {
        speechApi.cancel();
        var msg = new SpeechSynthesisUtterance();
        msg.text = ttsText;
        msg.voice = voices[voice];
        speechApi.speak(msg);
    }

    const updateSelectedVoice = (value) => {
        setSelectedVoice(value);
        localStorage.setItem('spelly-setting-ttsvoice', value);
        testSpeech(value);
    }

    const updateTtsText = (value) => {
        setTtsText(value);
        localStorage.setItem('spelly-setting-ttstext', value);
    }

    useEffect(() => {
        const sessionVoice = localStorage.getItem('spelly-setting-ttsvoice');
        if (sessionVoice) {
            setSelectedVoice(sessionVoice);
        }
        const sessionText = localStorage.getItem('spelly-setting-ttstext');
        if (sessionText) {
            setTtsText(sessionText);
        }
        setVoices(speechApi.getVoices());
    }, []);

    return (
      <div>
            <h1>Settings</h1>
            <div className="row g-3 align-items-center mb-3">
                <div className="col-auto">
                    <label htmlFor="ttsvoice" className="col-form-label">Voice</label>
                </div>
                <div className="col-auto">
                    <select className="form-control" id="ttsvoice" value={selectedVoice} onChange={(e) => updateSelectedVoice(parseInt(e.target.value))}>
                        {voices.map((voice, i) => (
                            <option key={`voice-${i}`} value={i}>{voice.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row g-3 align-items-center mb-3">
                <div className="col-auto">
                    <label htmlFor="ttstext" className="col-form-label">Test</label>
                </div>
                <div className="col-auto">
                    <input id="ttstext" className="form-control" value={ttsText} onChange={(e) => updateTtsText(e.target.value)} />
                </div>
                <div className="col-auto">
                    <button type="button" className="btn btn-dark" onClick={() => testSpeech(selectedVoice)} disabled={!ttsText}>Test</button>
                </div>
            </div>
      </div>
    );
}
