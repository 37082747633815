import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './components/nav-menu/nav-menu';
import { Footer } from './components/footer/footer';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavMenu />
        <Container>
          {this.props.children}
          <Footer/>
        </Container>
      </div>
    );
  }
}
