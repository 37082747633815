import React, { useEffect, useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { NewSpellingList } from '../admin/new-spelling-list/new-spelling-list';
import './spelling-lists.css';
import { SpellingListWord } from '../spelling-list-word/spelling-list-word';

export function SpellingLists({ admin, code }) {
    const [accordionOpen, setAccordionOpen] = useState('');
    const [spellingLists, setSpellingLists] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState(-2);
    const [wordPart, setWordPart] = useState(-1);
    const [playing, setPlaying] = useState(false);
    const [showAddSpellingList, setShowAddSpellingList] = useState(false);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Code': code
    }
    const speechApi = window.speechSynthesis;

    const toggleAccordion = (id) => {
        if (accordionOpen === id) {
            setAccordionOpen('');
        } else {
            setAccordionOpen(id);
        }
    }

    const getSpellingLists = async () => {
        const response = await fetch('api/spelly', {
            headers
        });
        const data = await response.json();
        setSpellingLists(data);
    }

    const spellingListAdded = (spellingList) => {
        setShowAddSpellingList(false);
        setSpellingLists(lists => [...lists, spellingList]);
    }

    const playWord = (word) => {
        speechApi.cancel();
        setWordPart(-2);
        setPlaying(true);
        var msg = new SpeechSynthesisUtterance();
        msg.onboundary = handleBoundary;
        msg.onend = () => setPlaying(false);
        msg.text = word.pronounciation.replaceAll('-', ' ');
        msg.voice = speechApi.getVoices()[selectedVoice];
        msg.rate = 0.5;
        speechApi.speak(msg);
        msg.text = word.word;
        speechApi.speak(msg);
    }

    const handleBoundary = (e) => {
        setWordPart(value => value + 1);
    }

    useEffect(() => {
        const sessionVoice = localStorage.getItem('spelly-setting-ttsvoice');
        if (sessionVoice) {
            setSelectedVoice(sessionVoice);
        }
        async function loadData() {
            await getSpellingLists();
        }
        loadData()
    }, [code])

    return (
        <div>
            <h5>Spelling Lists</h5>
            {admin &&
                <button className="btn btn-primary" onClick={() => setShowAddSpellingList(true)} >Add Spelling List</button>
            }
            {spellingLists.length === 0 && (
                <span className="empty-list">There are no spelling lists...</span>
            )}
            <Accordion open={accordionOpen} toggle={toggleAccordion}>
                {spellingLists.map((list, index) => (
                    <AccordionItem key={list.id}>
                        <AccordionHeader targetId={list.id} >{list.title}</AccordionHeader>
                        <AccordionBody accordionId={list.id}>
                            <ul>
                                {list.words.map((word, windex) => (
                                    <li key={`word-${index}-${windex}`}><SpellingListWord word={word} part={wordPart} playing={playing} playCallback={playWord} /></li>
                                ))}
                            </ul>
                        </AccordionBody>
                    </AccordionItem>
                ))}
            </Accordion>

            <Modal isOpen={showAddSpellingList} backdrop={true} toggle={() => setShowAddSpellingList(false)}>
                <ModalHeader toggle={() => setShowAddSpellingList(false)}>
                    Add New User
                </ModalHeader>
                <ModalBody>
                    <NewSpellingList callback={spellingListAdded} code={code} />
                </ModalBody>
            </Modal>
        </div>
    );
}