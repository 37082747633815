import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NewUser } from './new-user/new-user';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './admin.css';
import { SpellingLists } from '../spelling-lists/spelling-lists';
import { NewSpellingList } from './new-spelling-list/new-spelling-list';
export function Admin() {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [showNewUserForm, setShowNewUserForm] = useState(false);
    const { code } = useParams();
    const [selectedCode, setSelectedCode] = useState(code);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    if (code) {
        headers.code = code;
    }   

    const getUsers = async () => {
        const response = await fetch('api/admin/users', {
            headers
        });
        const data = await response.json();
        setUsers(data);
        setLoading(false);
    }

    const switchUser = async (newcode) => {
        localStorage.setItem('spelly-admin-code', newcode);
        setSelectedCode(newcode);
    }

    const newUserAdded = async (newUser) => {
        setShowNewUserForm(false);
        setUsers(users => [...users, newUser]);
        await switchUser(newUser.code);
    }

    const getSelectedUserName = () => {
        return users.find(u => u.code === selectedCode)?.name ?? '-';
    }

    useEffect(() => {
        document.addEventListener("visibilitychange", async () => {
            if (!document.hidden) {
                await getUsers();
            }
        });

        if (code) {
            localStorage.setItem('spelly-admin-code', code);
        } else {
            const userCode = localStorage.getItem('spelly-code');
            if (userCode) {
                setSelectedCode(userCode);
            } else {
                const adminCode = localStorage.getItem('spelly-admin-code');
                if (adminCode) {
                    setSelectedCode(adminCode);
                }
            }
        }

        async function loadData() {
            await getUsers();
        }
        loadData()
    }, [])

    return (
      <div>
        <h1>Spelly Admin</h1>
            {loading && (
                <span className="loading">Loading...</span>
            )}
            {!loading && (

                <div>
                    <button className="btn btn-danger" onClick={() => setShowNewUserForm(true)}>New User</button>
                    <select className="form-control" value={selectedCode} onChange={(e) => switchUser(e.target.value)}>
                        <option value="">Select User</option>
                        {users.map((user, index) => (
                            <option value={user.code} key={`user-${index}`}>
                                {user.name}
                            </option>
                        ))}
                    </select>

                    {selectedCode && (
                        <div className="user-data">
                            <div>
                                <div className="user-name">{getSelectedUserName()} [{selectedCode}]</div>
                                <SpellingLists code={selectedCode} admin={true} />
                            </div>
                        </div>
                    )}
                </div>
            )}
            <Modal isOpen={showNewUserForm} backdrop={true} toggle={() => setShowNewUserForm(false)}>
                <ModalHeader toggle={() => setShowNewUserForm(false)}>
                    Add New User
                </ModalHeader>
                <ModalBody>
                    <NewUser callback={newUserAdded} />
                </ModalBody>
            </Modal>
      </div>
    );
}
