import React, { useState } from 'react';
import "./new-spelling-list.css"

export function NewSpellingList({callback, code}) {
    const [newTitle, setNewTitle] = useState("");
    const [newDate, setNewDate] = useState("");
    const [newWords, setNewWords] = useState([]);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    const formValid = () => {
        return newTitle.length > 0
            && newDate.length > 0
            && newWords.length > 0
            && newWords.filter(f => !wordValid(f)).length === 0;
    }

    const wordValid = (word) => {
        const visibleParts = word.visible.trim().split(' ').length;
        const pronounceParts = word.pronounciation.trim().split(' ').length;
        return word.visible.length > 0 && word.pronounciation.length > 0 && pronounceParts === visibleParts;
    }

    const submitNewSpellingList = async (e) => {
        e.preventDefault();
        const response = await fetch('api/spelly/create', {
            method: 'POST',
            headers: { ...headers, code },
            body: JSON.stringify({ title: newTitle, date: newDate, words: generateWords() })
        });
        const newList = await response.json();
        setNewTitle("");
        setNewDate("");
        callback(newList);
    }

    const generateWords = () => {
        return newWords.map(w => {
            const visibleParts = w.visible.trim().split(' ');
            const pronounceParts = w.pronounciation.trim().split(' ');
            return {
                word: w.visible.replaceAll(' ', ''),
                pronounciation: w.pronounciation.trim().replaceAll(' ', '-'),
                parts: visibleParts.map((x, i) => {
                    return {
                        order: i,
                        visible: x,
                        pronounce: pronounceParts[i]
                    }
                })
            }
        })
    }

    const addWord = () => {
        setNewWords(newWords => [...newWords, { visible: '', pronounciation: '' }]);
    }

    const updateWord = (index, value) => {
        setNewWords(words => words.map((w, i) => i === index ? { ...w, visible: value.replaceAll('-', ' ') } : w));
    }

    const updatePronounciation = (index, value) => {
        setNewWords(words => words.map((w, i) => i === index ? { ...w, pronounciation: value.replaceAll('-', ' ') } : w));
    }

    return (
        <form onSubmit={submitNewSpellingList}>
            <div className="form-row mb-3">
                <div className="input-text">
                    <label htmlFor="title" className="form-label">Title</label>
                    <input type="text" className={`form-control ${newTitle.length > 0 ? 'is-valid' : ''}`} id="title" value={newTitle} onChange={(e) => setNewTitle(e.target.value)} />
                </div>
                <div className="input-text">
                    <label htmlFor="date" className="form-label">Date</label>
                    <input type="date" className={`form-control ${newDate.length > 0 ? 'is-valid' : ''}`} id="date" value={newDate} onChange={(e) => setNewDate(e.target.value)} />
                </div>
                <button type="button" className="btn btn-primary" onClick={addWord}>Add Word</button>
            </div>
            {newWords.map((word, index) => (
                <div className="form-row mb-3" key={`word-${index}`} >
                    <label className="form-label col-auto">Word</label>
                    <input type="text" className="form-control col-auto" value={word.visible} onChange={(e) => updateWord(index, e.target.value)} />
                    <label className="form-label col-auto">Pronounciation</label>
                    <input type="text" className="form-control col-auto" value={word.pronounciation} onChange={(e) => updatePronounciation(index, e.target.value)} />
                </div>
            ))}
            <div className="row">
                <div className="form-button">
                    <button type="submit" disabled={!formValid()} className={`btn ${formValid() ? 'btn-primary' : 'btn-light'}`}>Submit</button>
                </div>
            </div>
        </form>
    );
}