import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './lobby.css';
import { SpellingLists } from '../spelling-lists/spelling-lists';

export function Lobby() {
    const { code } = useParams();
    const [userCode, setUserCode] = useState(code);
    const navigate = useNavigate();
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    if (code) {
        headers.code = code;
    }

    const checkCode = async (code) => {
        if (!code || code.length === 0) {
            return false;
        }
        const response = await fetch('api/admin/checkcode/' + code, {
            headers
        });
        const data = await response.json();
        return data.exists;
    }

    const init = async () => {
        let validCode = false;
        if (code) {
            if (await checkCode(code)) {
                localStorage.setItem('spelly-code', code);
                headers.code = code;
                validCode = true;
            }
        } else {
            const sessionCode = localStorage.getItem('spelly-code');
            if (sessionCode) {
                if (await checkCode(sessionCode)) {
                    setUserCode(sessionCode);
                    headers.code = sessionCode;
                    validCode = true;
                }
            }
        }

        if (!validCode) {
            localStorage.clear('spelly-code');
            navigate('/');
        }
    }

    useEffect(() => {
        init();        
    }, []);

    return (
      <div>
            <h1>Spelly</h1>
            {userCode &&
                <SpellingLists code={userCode} />
            }
      </div>
    );
}
