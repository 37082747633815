import React, { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './footer.css';

export function Footer(props) {
    
    return (
        <div className="footer mt-5">
            v0.0.1 &copy;2023 Michael LaValley
        </div>
    );
}
