import React from 'react';
import './spelling-list-word.css';

export function SpellingListWord({ word, part, playing, playCallback }) {
    console.log(word);
    return (
        <div className="row">
            <div className="col-auto">
                <span className="word">
                    {word.parts.map((p, i) => (
                        <span className={playing && part === i ? 'active' : ''}>{p.visible}</span>
                    ))}
                </span>
                <span className="pronounciation">
                    {word.parts.map((p, i) => (
                        <span className={playing && part === i ? 'active' : ''}>{p.pronounce}{i < word.parts.length - 1 ? '-' : ''}</span>
                    ))}
                </span>
            </div>
            <div className="col-auto">
                <button className="btn btn-dark" onClick={() => playCallback(word)}>Play</button>
            </div>
        </div>
    );
}