import React, { useState } from 'react';
import "./new-user.css"

export function NewUser({callback}) {
    const [newUserName, setNewUserName] = useState("");
    const [newUserCode, setNewUserCode] = useState("");
    const [codeValid, setCodeValid] = useState(false);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    const MIN_CODE_LENGTH = 3;
    const MAX_CODE_LENGTH = 7;

    const checkCode = async (code) => {
        const response = await fetch('api/admin/checkCode/' + code, { headers })
        const data = await response.json();
        return !data.exists;
    }

    const codeChanged = async (e) => {
        const code = e.target.value;
        const valid = code.length >= MIN_CODE_LENGTH && code.length <= MAX_CODE_LENGTH && checkCode(code);
        setNewUserCode(code);
        setCodeValid(valid);
    }

    const formValid = () => {
        return codeValid && newUserName.length > 0;
    }

    const submitNewUser = async (e) => {
        e.preventDefault();
        const response = await fetch('api/admin/createUser', {
            method: 'POST',
            headers,
            body: JSON.stringify({ name: newUserName, code: newUserCode })
        });
        const newUser = await response.json();
        setNewUserName("");
        setNewUserCode("");
        callback(newUser);
    }

    return (
        <form onSubmit={submitNewUser}>
            <div className="form-row">
                <div className="input-text">
                    <label htmlFor="potato" className="form-label">Name</label>
                    <input type="text" className={`form-control ${newUserName.length > 0 ? 'is-valid' : ''}`} id="potato" value={newUserName} onChange={(e) => setNewUserName(e.target.value)} />
                </div>
                <div className="input-text">
                    <label htmlFor="newUserCode" className="form-label">Code</label>
                    <input type="text" className={`form-control ${codeValid ? 'is-valid' : ''}`} id="newUserCode" value={newUserCode} onChange={codeChanged} />
                </div>
                <div className="form-button">
                    <button type="submit" disabled={!formValid()} className={`btn ${formValid() ? 'btn-primary' : 'btn-light'}`}>Submit</button>
                </div>
            </div>
        </form>
    );
}